import React from 'react';
import YouTubePlayer from './YouTubePlayer';
import './App.css'; // Import the updated CSS file
import logo from './Assets/logo.png'; // Import your logo image file

function App() {
  const videoIds = [
    "oJERqOsc7bU",
    "NO7MnQ1gj2s",
    "bnA2WxBMB7E",
    "vmaf__YdCQc",
    
  ];
  return (
    <div className="app">
      <img src={logo} alt="Logo" className="logo" />
      <YouTubePlayer videoIds={videoIds} />
      <div className="copyright">
        &copy; {new Date().getFullYear()} SATV. All rights reserved.
      </div>
    </div>
  );
}

export default App;
