import React, { useState } from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoIds }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const opts = {
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      modestbranding: 1,
      showinfo: 0,
      rel: 1,
      loop: 2,
      mute: 2,
    },
  };

  const handleSwipe = (event) => {
    const { deltaX } = event.touches[0];

    if (deltaX > 0) {
      // Swipe right, go to the previous video
      setCurrentVideoIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (deltaX < 0) {
      // Swipe left, go to the next video
      setCurrentVideoIndex((prevIndex) =>
        Math.min(prevIndex + 1, videoIds.length - 1)
      );
    }
  };

  const previousVideo = () => {
    setCurrentVideoIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      Math.min(prevIndex + 1, videoIds.length - 1)
    );
  };

  return (
    <div style={{ backgroundColor: '#000', position: 'relative' }} onTouchMove={handleSwipe}>
      <YouTube videoId={videoIds[currentVideoIndex]} opts={opts} />
      <div className="swipe-buttons">
        <button className="swipe-button" onClick={previousVideo}>
          Previous
        </button>
        <button className="swipe-button" onClick={nextVideo}>
          Next
        </button>
      </div>
    </div>
  );
};

export default YouTubePlayer;
